button {
    padding: 10px 20px;
    border: 2px solid rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
    outline: none;
}

button:hover {
    background: rgba(255, 255, 255, 0.3);
}

select,
label {
    display: inline;
    width: auto;
    margin: 8px 4px;
}

select {
    padding: 8px;
    border: 1px solid #cccccc3e;
    border-radius: 400px;
    background-color: rgba(19, 23, 30, 0.98);
    color: white;
    -webkit-appearance: none;
    appearance: none;  
}  

label {
    font-weight: bold;
}

fieldset {
    border: 1px solid #cccccc3e;
    border-radius: 10px;
    background-color: rgba(19, 23, 30, 0.554);
}