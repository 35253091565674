/* ProviderButton.css */
.provider-button {
  padding: 10px 20px;
  border: 2px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  outline: none;
}

.provider-button:hover {
  background: rgba(255, 255, 255, 0.3);
}

.provider-button.disconnected {
  background: rgba(128, 128, 128, 0.2);
  /* Grey when disconnected */
  border-color: rgba(128, 128, 128, 0.4);
}

.provider-button.connected {
  background: rgba(0, 255, 0, 0.2);
  /* Joyful color when connected */
  border-color: rgba(0, 255, 0, 0.4);
}