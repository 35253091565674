.message-container {
    position: fixed;
    backdrop-filter: blur(5px);
    color: white;
    padding: 10px;
    z-index: 1000;
    border-radius: 5px;
    background: rgb(50,54,61);
    border: 1px solid rgba(128, 128, 128, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.message-container.error {
    background: rgb(81,12,16);
    border-color: rgba(150, 18, 0, 0.4);
}

.message-container.success {
    background: rgb(77,101,20);
    border-color: rgba(0, 200, 0, 0.4);
}

.message-container.info {
    background: rgb(50,54,61);
    border-color: rgba(100, 100, 100, 0.4);
}

.message-button,
.dismiss-button {
    margin: 0 5px;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-weight: bold;
}

.message-button {
    background-color: rgba(139, 139, 139, 0.3);
    color: white;
    margin: 0 10px;
}

.dismiss-button {
    background-color: transparent;
    color: white;
    font-size: 20px;
    margin-left: 10px;
}

.message-container a,
.message-container a:visited {
    color: white;
    text-decoration: underline;
}