/* index.css or App.css */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: #000000; /* Base dark background */
  overflow: hidden;
}

.background-shapes {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Below the glass background but above the base layer */
}

.circle {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2); /* Light background color for glass effect */
  backdrop-filter: blur(10px); /* Apply blur for glass effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
}

.circle.large {
  width: 600px;
  height: 600px;
  top: -250px;
  right: 150px;
  background-color: rgb(255, 14, 14); /* Red color */
}

.circle.medium {
  width: 400px;
  height: 400px;
  top: 0px;
  left: -100px;
  background-color: rgba(255, 187, 0, 0.5); /* Green color */
}

.circle.small {
  width: 200px;
  height: 200px;
  top: 100px;
  right: 50px;
  background-color: rgb(179, 255, 0); /* Blue color */
}


/* Define your circles here, similar to previous examples */

.glass-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(17, 25, 40, 0.75);
  backdrop-filter: blur(160px) saturate(180%);
  -webkit-backdrop-filter: blur(160px) saturate(180%);
  z-index: 2; /* Ensure this is above the shapes but below the content */
}

.app-content {
  position: relative;
  z-index: 3; /* Above the glass background */
  color: white; /* Ensure content is visible on the dark/glass background */
}

.pages {
  padding: 20px;
}