.csv-import {
  margin: 1rem 0;
  padding: 1rem;
  border: 2px dashed #ccc;
  border-radius: 4px;
  transition: all 0.2s ease;
  background-color: #f9f9f9;
}

.csv-import.dragging {
  border-color: #2196f3;
  background-color: #e3f2fd;
}

.csv-import textarea {
  width: 100%;
  min-height: 150px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.5;
  resize: vertical;
  margin-bottom: 0.5rem;
}

.csv-import textarea:focus {
  outline: none;
  border-color: #2196f3;
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.1);
}

.csv-format {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
  text-align: center;
} 