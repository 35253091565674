.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 4vw;
  background: rgba(255, 255, 255, 0.076);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.354); /* Glassmorphic bottom border */

  ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;

    li {
      margin-right: 20px;
    }

    li:last-child {
      margin-right: 0;
    }

    /* Plain text style for 'Home' */
    li:first-child a {
      background: none;
      border: none;
      padding-left: 0;
      padding-right: 50px;
    }

    /* Glassmorphic effect for other links/buttons */
    li:not(:first-child) a {
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.3);
    }

    li:not(:first-child) a:hover {
      background: rgba(255, 255, 255, 0.2);
      color: #ddd; /* Slightly change the color on hover */
    }
  }

  a {
    text-decoration: none;
    color: white; /* Adjust as needed */
    padding: 8px 16px;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
}
